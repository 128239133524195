<template>
    <v-card id="create-component">
        <v-toolbar
                fixed
                dark
                color="primary"
        >
            <v-btn
                    icon
                    dark
                    @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('generic.createEvent') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                        dark
                        text
                        @click.prevent="confirm"
                >
                    Save
                </v-btn>
            </v-toolbar-items>

        </v-toolbar>

        <v-container fluid class="pb-0">
            <v-row align="center">

                <v-col class="d-flex" cols="12" md="3">
                    <v-select
                            v-model="form.type" :items="selectType" outlined dense chips small-chips
                            :label="$t('generic.type')" @input="setTournament()"

                    ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" md="3">
                    <v-autocomplete
                            v-model="form.sport" :items="getItemsSport" item-text="name" item-value="id"
                            outlined dense chips small-chips :label="$t('sport')" @input="getCategoriesFromSport()"

                    ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" md="3">
                    <v-autocomplete
                            :error-messages="messages.category"
                            v-model="form.category" :items="getItemsCategories" item-text="name" item-value="id"
                            outlined dense chips small-chips :label="$t('category')"
                            @input="getTournamentsFromCategories()" clearable

                    ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" md="3" v-if="form.type=='match'">
                    <v-autocomplete
                            :error-messages="messages.tournament"
                            v-model="form.tournament" :items="getItemsTournaments" item-text="name" item-value="id"
                            outlined dense chips small-chips :label="$t('tournament')" clearable
                            @input="getCompetitorByTournaments()"
                            append-outer-icon="mdi-plus"

                            @click:append-outer="dialog = !dialog"
                    ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" md="3" v-else>
                    <v-autocomplete
                            :error-messages="messages.tournament"
                            v-model="form.tournament" :items="getItemsTournaments" item-text="name" item-value="id"
                            outlined dense chips small-chips :label="$t('tournament')" clearable

                            append-outer-icon="mdi-plus"

                            @click:append-outer="dialog = !dialog"
                    ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" md="3" v-if="form.type=='match'">
                    <v-autocomplete
                            :error-messages="messages.competitor"
                            v-model="form.competitors" :items="getItemsCompetitors" item-text="name" item-value="id"
                            outlined multiple dense chips small-chips :label="$t('competitor')" clearable
                            append-outer-icon="mdi-plus"

                            @click:append-outer="dialogcompetitor = !dialogcompetitor"
                    ></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" md="3">

                    <v-select class="text-capitalize" :items="typeOfStatus" item-text="text" item-value="value"
                              :label="$t('bets.status')" dense outlined v-model="form.status"></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" md="3">

                    <v-menu
                            ref="startDatemenu"
                            v-model="startDate.menu"
                            :close-on-content-click="false"
                            :return-value.sync="startDate.date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            dense outlined
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="startDate.date"
                                    :label="$t('bets.date')+ ' '+ $t('scheduled')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense outlined
                                    :error-messages="messages.startDate"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="startDate.date"
                                no-title
                                scrollable
                                dense outlined

                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="startDate.menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.startDatemenu.save(startDate.date)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>

                <v-col class="d-flex" cols="12" md="3">

                    <v-menu
                            ref="startTimemenu"
                            v-model="startTime.menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="startTime.time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="startTime.time"
                                    :label="$t('hour')+' '+ $t('scheduled')"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense outlined
                                    :error-messages="messages.startTime"
                            ></v-text-field>
                        </template>
                        <v-time-picker
                                v-if="startTime.menu2"
                                v-model="startTime.time"
                                full-width
                                @click:minute="$refs.startTimemenu.save(startTime.time)"
                                dense outlined
                                format="24hr"
                        ></v-time-picker>
                    </v-menu>
                </v-col>

                <v-col class="d-flex" cols="12" md="12" v-if="form.type=='match'">

                    <v-autocomplete
                            v-model="odds"
                            :items="sportMarkets"
                            :label="$t('markets')"
                            chips
                            deletable-chips
                            multiple
                            small-chips
                            return-object
                            item-text="name"
                            dense outlined

                    ></v-autocomplete>

                </v-col>
                <v-col class="d-flex" cols="12" md="12" v-else>

                    <v-autocomplete
                            v-model="odds"
                            :items="sportMarkets"
                            :label="$t('markets')"
                            chips
                            deletable-chips
                            multiple
                            small-chips
                            return-object
                            item-text="name"
                            dense outlined
                            append-outer-icon="mdi-plus"

                            @click:append-outer="dialogmarket = !dialogmarket"


                    ></v-autocomplete>

                </v-col>


            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card flat>
                        <div class="d-flex-row">

                            <div class="col-md-3" v-for="(odd,j) in form.odds"
                            >
                                <div class="box-market">
                                    <div class="box-header">

                                        <div class="market-name" style="width: 100%">{{ odd.name }}</div>


                                    </div>
                                    <div class="box-body-create" v-for="(outcome,index) in odd.outcomes" >
                                        <v-row >
                                        <v-col class="d-flex pt-0 pb-0 " cols="12" md="9" v-if="outcome.id" style="font-size: 16px" >

                                          [{{ outcome.id | truncate(5,'...') }}] {{ outcome.name }}

                                        </v-col>
                                        <v-col class="d-flex pt-0 pb-0" cols="12" md="9" v-else>
                                                <v-text-field v-model="outcome.name"   dense hide-details ></v-text-field>
                                        </v-col>
                                        <v-col class="d-flex pt-0 pb-0" cols="12" md="3" v-if="outcome.id!=''">
                                            <v-text-field v-model="outcome.value" type="number" step="0.01" dense hide-details reverse ></v-text-field>
                                        </v-col>
                                            <v-col class="d-flex pt-0 pb-0 mt-0 " cols="12" md="3" v-else>
                                                <v-text-field v-model="outcome.value" type="number" step="0.01" dense hide-details reverse append-outer-icon="mdi-plus" @click:append-outer="addOutcome(odd)" v-if="odd.outcomes.length==index+1"></v-text-field>
                                                <v-text-field v-model="outcome.value" type="number" step="0.01" dense hide-details reverse append-outer-icon="mdi-minus" @click:append-outer="deleteOutcome(odd,index)"  v-else></v-text-field>
                                               <!-- <v-text-field
                                                              v-model="outcome.value" append-outer-icon="mdi-plus"
                                                               @click:append-outer="addOutcome(odd)"
                                                              v-if="odd.outcomes.length==index+1"
                                                              type="number"
                                                              step="0.01"
                                                              reverse
                                                              hide-details
                                                ></v-text-field>
                                                <v-text-field   hide-details
                                                              v-model="outcome.value" append-outer-icon="mdi-minus"
                                                              @click:append-outer="deleteOutcome(odd,index)"
                                                              type="number"
                                                              step="0.01"
                                                              reverse
                                                              v-else></v-text-field>-->

                                            </v-col>

                                        </v-row>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>

        </v-container>
        <v-snackbar
                v-model="snackbar.snackbar"
                :color="snackbar.color"
                :multi-line="snackbar.mode === 'multi-line'"
                :timeout="snackbar.timeout"
                :vertical="snackbar.mode === 'vertical'"
        >
            {{ snackbar.text }}
            <v-btn
                    dark
                    text
                    @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
        <v-dialog
                v-model="dialog"
                max-width="500px"
        >
            <v-card>
                <v-card-text>
                    <v-text-field
                            :error-messages="messages.addtournament"
                            v-model="formTournament.name" :label="this.$t('tournament')"></v-text-field>


                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            text
                            color="primary"
                            @click="addTournament()"
                    >
                        {{this.$t('generic.save')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="dialogmarket"
                max-width="500px"
        >
            <v-card>
                <v-card-text>
                    <v-text-field
                            :error-messages="messages.addmarket"
                            v-model="formMarket.name" :label="this.$t('market')"></v-text-field>


                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            text
                            color="primary"
                            @click="addMarket()"
                    >
                        {{this.$t('generic.save')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="dialogcompetitor"
                max-width="500px"
        >
            <v-card>
                <v-card-text>
                    <v-text-field
                            :error-messages="messages.addcompetitor"
                            v-model="formCompetitor.name" :label="this.$t('bets.competitor')"></v-text-field>


                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            text
                            color="primary"
                            @click="addCompetitor()"
                    >
                        {{this.$t('generic.save')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>

</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import axios from "axios";

    export default {
        props: ['FunctionParent'],
        name: "Create",
        data() {
            return {
                dialog: false,
                dialogmarket: false,
                dialogcompetitor: false,
                form: {
                    sport: 8412615417,
                    category: '',
                    tournament: '',
                    type: 'match',
                    competitors: [],
                    status: 1,
                    odds: [],
                    scheduled: '',
                    platform: '',

                },
                formTournament: {
                    sport: '',
                    category: '',
                    name: ''
                },
                formCompetitor: {
                    name: ''
                },
                formMarket: {
                    name: ''
                },
                messages: {
                    category: '',
                    tournament: '',
                    competitor:'',
                    startDate: '',

                    startTime: '',
                    finishTime:'',
                    addtournament: '',
                    addmarket: '',
                    addcompetitor : ''
                },
                startDate: {
                    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                    menu: false,
                    modal: false,
                    menu2: false,
                },
                startTime: {
                    time: null,
                    menu2: false,
                    modal2: false,
                },
                /*finishDate: {
                    date: "",
                    menu: false,
                    modal: false,
                    menu2: false,
                },
                finishTime: {
                    time: null,
                    menu2: false,
                    modal2: false,
                },*/

                typeOfStatus: [

                    {text: this.$t('nsy'), value: 1},
                    {text: this.$t('inProgress'), value: 2},
                    {text: this.$t('finished'), value: 3},
                    {text: this.$t('cancelled'), value: 4},
                    {text: this.$t('postponed'), value: 5},
                    {text: this.$t('interrupted'), value: 6},
                    {text: this.$t('abandoned'), value: 7},
                    {text: this.$t('coverageLost'), value: 8},
                    {text: this.$t('aboutToStart'), value: 9},
                ],
                headers: [
                    {text: this.$t('generic.id'), value: 'unique_id'},
                    {text: this.$t('generic.name'), value: 'name'},
                    {text: this.$t('generic.actions'), value: 'actions'},
                ],
                snackbar: {
                    snackbar: false,
                    color: '',
                    mode: '',
                    timeout: 6000,
                    text: '',
                },
                sportMarkets: [],
                search: '',
                searchSport: -1,
                dataGroups: {},
                dataPreview: [],
                showAdd: false,
                marketToEdit: {},
                showTranslations: false,
                dialogconfirm: false,
                selectType: ['match', 'antepost'],
                odds: [],
                statusEvent:0
            }
        },
        watch: {
            odds: async function (val) {
                //console.log(val)
                if (_.size(val) > 0) {
                    await this.setPreload(true);
                    for await(let el of val) {

                        var check = _.find(this.form.odds, {unique_id: el.unique_id})
                        //   console.log(check)
                        if (!check) {
                            //if(!el.outcomes){
                            _.assignIn(el, {'outcomes': []})
                            var resp = await this.fetchMarketDetail({market: el.id})
                            for await(let out of resp.outcomes) {
                                el.outcomes.push({id: out.id, name: out.name, value: ''})
                            }
                            this.form.odds.push(el)
                        }
                    }
                    await this.setPreload(false);
                    this.form.odds.forEach((odd,index)=>{
                     //   console.log(odd,index)
                        var check = _.find(val,{name:odd.name});
                        if(!check){
                            this.form.odds.splice(index, 1)
                        }
                    })


                }else{
                    this.form.odds=[]
                }
                //console.log("odds",val)
            },
        },
        async mounted() {
            await this.setPreload(true);
            await this.fetchSports({'language': this.getLanguage});
            await this.fetchCategories({'language': this.getLanguage, 'paramKey': this.form.sport});
            this.sportMarkets = await this.fetchMarket({
                enableSpecifiers: false,
                lang: this.getLanguage,
                sport: this.form.sport,
                id: this.form.sport
            })
            this.form.platform = this.getPlatformId
            await this.setPreload(false);
        },
        computed: {
            ...mapGetters({
                'getToken': 'profile/getToken',
                'getUrl': 'settings/getUrl',
                'getLanguage': 'profile/getLanguage',
                'getSports': 'sport/getSports',
                'getProducers': 'producers/getList',
                'getSubDialog': 'settings/getSubDialog',
                'getPlatformId': 'rules/getPlatformId',
                'getItemsSport': 'sport/getItems',
                'getItemsCategories': 'category/getItems',
                'getItemsTournaments': 'tournament/getItems',
                'getItemsCompetitors': 'competitors/getItems',
                'getMarkets': 'utils/getMarkets',
            }),

        },
        methods: {
            ...mapMutations({
                'setDialog': 'settings/setDialog',
                'setPreload': 'settings/setPreload',
                'setSnackbar': 'snackbars/setSnackbar',
                'setSubDialog': 'settings/setSubDialog',
                'fetchSports': 'sport/fetchSports',
                'fetchCategories': 'category/fetchCategories',
            }),
            ...mapActions({
                'setPreload': 'utils/setPreload',
                'fetchEventsList': 'event/fetchEventsList',
                'setCriteria': 'event/setCriteria',
                'fetchSports': 'sport/fetchSports',
                'fetchCategories': 'category/fetchCategories',
                'fetchTournaments': 'tournament/fetchTournaments',
                'fetchCompetitorByTournaments': 'competitors/fetchCompetitorByTournaments',
                'resetTournaments': 'tournament/resetTournaments',
                'fetchEventsBooked': 'live/fetchEventsBooked',
                'fetchMarket': 'stamps/fetchMarketOutcome',
                'fetchMarketDetail': 'market/fetchMarket',
                'addTurnament': 'tournament/addTurnament',
                'addCompetitors': 'competitors/addCompetitor',
            }),
            close() {
                if (this.getSubDialog) {
                    this.setSubDialog(false)
                } else {
                    this.setDialog(false)
                }
            },
            async setTournament() {
                console.log(this.form.type)
            },
            //
            confirm() {

                this.messages = {
                    category: '',
                    tournament: '',
                    competitor:'',
                    startDate: '',
                    startTime: '',
                    finishDate: '',
                    finishTime: '',
                    addtournament: '',
                    addmarket: '',
                    addcompetitor: '',
                }

                    this.errors = []
                if (this.form.category == "") {
                    this.errors.push(this.$t('category') + ' ' + this.$t('error'));
                    _.set(this.messages, `category`, this.$t('category') + ' ' + this.$t('error'))
                }
                if (this.form.tournament == "") {
                    this.errors.push(this.$t('tournament') + ' ' + this.$t('error'));
                    _.set(this.messages, `tournament`, this.$t('tournament') + ' ' + this.$t('error'))
                }
                /*if (this.startDate.date == "") {
                    this.errors.push(this.$t('date') + ' ' + this.$t('error'));
                    _.set(this.messages, `date`, this.$t('date') + ' ' + this.$t('error'))
                }*/
                if (!this.startDate.date) {
                    this.errors.push(this.$t('date') + ' ' + this.$t('error'));
                    _.set(this.messages, `startDate`, this.$t('date') + ' ' + this.$t('error'))
                }
                if (!this.startTime.time) {
                    this.errors.push(this.$t('hour') + ' ' + this.$t('error'));
                    _.set(this.messages, `startTime`, this.$t('hour') + ' ' + this.$t('error'))
                }
                /*if (this.finishDate.date == "") {
                    this.errors.push(this.$t('date') + ' ' + this.$t('error'));
                    _.set(this.messages, `date`, this.$t('date') + ' ' + this.$t('error'))
                }*/
                /*if (!this.finishDate.date) {
                    this.errors.push(this.$t('date') + ' ' + this.$t('error'));
                    _.set(this.messages, `finishDate`, this.$t('date') + ' ' + this.$t('error'))
                }
                if (!this.finishTime.time) {
                    this.errors.push(this.$t('hour') + ' ' + this.$t('error'));
                    _.set(this.messages, `finishTime`, this.$t('hour') + ' ' + this.$t('error'))
                }*/


                if (_.size(this.errors) > 0) {

                    var testo = '';
                    this.errors.forEach(value => {
                        testo += value + '.';

                    })


                    this.snackbar.color = "error";
                    this.snackbar.text = testo;
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;


                } else {


                    let url = "api/store-event";


                   // this.form.scheduled = `${this.startDate.date}T${this.startTime.time}:00.000Z`
                    this.form.scheduled = `${this.startDate.date} ${this.startTime.time}:00`


                    let payload = this.form;
                    this.setPreload(true);
                    this.messages = [];
                    axios.post(`${this.getUrl}${url}`, payload, {'headers': {'Authorization': `Bearer ${this.getToken}`}})

                        .then(res => {
                            console.log(res)


                            this.setPreload(false);
                            this.snackbar.color = "success";
                            this.snackbar.text = "Operation success";
                            this.snackbar.snackbar = true;
                            this.snackbar.mode = 'multi-line';
                            this.snackbar.timeout = 5000;
                            this.setDialog(false);

                        })
                        .catch(err => {
                            if (err.response) {
                                let errors = err.response.data.error;
                                if (errors) {
                                    console.log(errors);
                                    errors.forEach(value => {

                                        this.messages[value.param] = value.msg
                                    })

                                }


                                this.snackbar.color = "error";
                                this.snackbar.text = err.message;
                                this.snackbar.snackbar = true;
                                this.snackbar.mode = 'multi-line';
                                this.snackbar.timeout = 5000;
                                this.preload = false;
                                if (err.response.status === 401) {
                                    this.setLogout();
                                    this.$router.push('/login')
                                }
                            }

                        });
                }
            },
            async getCategoriesFromSport() {
                await this.setPreload(true);
                await this.fetchCategories({'language': this.getLanguage, 'paramKey': this.form.sport});
                this.sportMarkets = await this.fetchMarket({
                    enableSpecifiers: true,
                    lang: this.getLanguage,
                    sport: -1,
                    id: this.form.sport
                })

                this.form.category = ''
                this.form.tournament = ''
                await this.setPreload(false);
            },
            async getTournamentsFromCategories() {
                await this.setPreload(true);
                await this.fetchTournaments({'language': this.getLanguage, 'paramKey': this.form.category});
                this.form.tournament = ''
                await this.setPreload(false);
            },
            async getCompetitorByTournaments() {
                await this.setPreload(true);
                this.form.competitors = []
                await this.fetchCompetitorByTournaments({
                    'language': this.getLanguage,
                    'paramKey': this.form.tournament
                });
                await this.setPreload(false);

            },
            addOutcome(odd){
                odd.outcomes.push({"id": "", "name": "", "value": ""})
            },
            deleteOutcome(odd,index){
                odd.outcomes.splice(index, 1)
            },
            async addTournament() {
                this.messages = {
                    category: '',
                    tournament: '',
                    competitor:'',
                    startDate: '',
                    startTime: '',
                    addtournament: '',
                    addmarket: '',
                    addcompetitor:''
                }

                    this.errors = []
                if (this.form.category == "") {
                    this.errors.push(this.$t('category') + ' ' + this.$t('error'));
                    _.set(this.messages, `category`, this.$t('category') + ' ' + this.$t('error'))
                }
                if (this.formTournament.name == "") {
                    this.errors.push(this.$t('tournament') + ' ' + this.$t('error'));
                    _.set(this.messages, `addtournament`, this.$t('tournament') + ' ' + this.$t('error'))
                }


                if (_.size(this.errors) > 0) {

                    var testo = '';
                    this.errors.forEach(value => {
                        testo += value + '.';

                    })


                    this.snackbar.color = "error";
                    this.snackbar.text = testo;
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;


                } else {
                    await this.addTurnament({name: this.formTournament.name})
                    this.form.tournament = this.formTournament.name


                    this.dialog = false

                }
            },
            async addCompetitor() {
                this.messages = {
                    category: '',
                    tournament: '',
                    competitor:'',
                    startDate: '',
                    startTime: '',
                    addtournament: '',
                    addmarket: '',
                    addcompetitor:''
                },

                    this.errors = []
                if (this.form.category == "") {
                    this.errors.push(this.$t('category') + ' ' + this.$t('error'));
                    _.set(this.messages, `category`, this.$t('category') + ' ' + this.$t('error'))
                }
                if (this.form.tournament == "") {
                    this.errors.push(this.$t('tournament') + ' ' + this.$t('error'));
                    _.set(this.messages, `tournament`, this.$t('tournament') + ' ' + this.$t('error'))
                }
                if (this.formCompetitor.name == "") {
                    this.errors.push(this.$t('competitor') + ' ' + this.$t('error'));
                    _.set(this.messages, `addcompetitor`, this.$t('competitor') + ' ' + this.$t('error'))
                }


                if (_.size(this.errors) > 0) {

                    var testo = '';
                    this.errors.forEach(value => {
                        testo += value + '.';

                    })


                    this.snackbar.color = "error";
                    this.snackbar.text = testo;
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;


                } else {
                    //await this.addTurnament({name: this.formTournament.name})
                    await this.addCompetitors({name:this.formCompetitor.name})

                    this.form.competitors.push(this.formCompetitor.name)


                    this.dialogcompetitor = false

                }
            },
            async addMarket() {
                this.messages = {
                    category: '',
                    tournament: '',
                    competitor:'',
                    startDate: '',
                    startTime: '',
                    addtournament: '',
                    addmarket: '',
                    addcompetitor:''
                },

                    this.errors = []
                if (this.form.category == "") {
                    this.errors.push(this.$t('category') + ' ' + this.$t('error'));
                    _.set(this.messages, `category`, this.$t('category') + ' ' + this.$t('error'))
                }
                if (this.form.tournament == "") {
                    this.errors.push(this.$t('tournament') + ' ' + this.$t('error'));
                    _.set(this.messages, `tournament`, this.$t('tournament') + ' ' + this.$t('error'))
                }
                if (this.formMarket.name == "") {
                    this.errors.push(this.$t('market') + ' ' + this.$t('error'));
                    _.set(this.messages, `addmarket`, this.$t('market') + ' ' + this.$t('error'))
                }


                if (_.size(this.errors) > 0) {

                    var testo = '';
                    this.errors.forEach(value => {
                        testo += value + '.';

                    })


                    this.snackbar.color = "error";
                    this.snackbar.text = testo;
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;


                } else {
                    //console.log("fabio")
                    //await this.addTurnament({name:this.formTournament.name})
                    this.sportMarkets.push({
                        id: '',
                        name: this.formMarket.name,
                        specifiers: '',
                        unique_id: "",
                        sort: 1000,
                        outcomes: []
                    })
                    this.odds.push({
                        id: '',
                        name: this.formMarket.name,
                        specifiers: '',
                        unique_id: "",
                        sort: 1000,
                        outcomes: []
                    })


                    this.form.odds.push({
                        id: '',
                        name: this.formMarket.name,
                        specifiers: '',
                        unique_id: "",
                        sort: 1000,
                        outcomes: [{"id": "", "name": "", "value": ""}]
                    })
                    this.dialogmarket = false

                }
            }
        }
    }

</script>

<style scoped>

</style>